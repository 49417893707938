<template>
    <div/>
</template>

<script>
    export default {
        name: "Base",
        props: {
            crumbs: {
                type: Array,
                default: () => []
            },
            header: {
                type: String,
                default: ''
            }
        },
        watch: {
            header: function () {
                this.updateCrumbsAndHeader()
            }
        },
        async created() {
            this.updateCrumbsAndHeader()
            this.onCreated()
            window.updateHeaderHandler = this
        },
        methods: {
            async onCreated() {
            },
            goToDashboard() {
                this.$emit('back-to-dashboard')
            },
            updateCrumbsAndHeader() {
                this.$emit('childinit', this.crumbs, this.header)
            },
            updateHeader(header){
                this.$emit('childinit', this.crumbs, header)
            }
        }
    }
</script>

<style scoped>

</style>
