import axios from 'axios'

export default {
    logout: () => axios.get('/api/logout'),
    getEmail: () => axios.get('/api/me')
        .then(response => {
            if (response.status < 400) {
                return response.data
            }
            throw Error
        })
        .catch(() => window.open('/api/oauth2/authorization/tgo', '_self'))
}
