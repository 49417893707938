<template>
    <div>
        <div class="welcome">
            Your feedback is welcome
        </div>
        <div class="improve">
            We'll use your feedback to improve our service.
        </div>

        <div class="buttons">
            <TUTD
                :initial="this.$route && this.$route.query && this.$route.query.initial"
                @picked="onPicked"
            />
            <span>Click to rate</span>
        </div>

        <div class="text-area-container">
            <textarea
                v-model="text"
                placeholder="Please provide your feedback here. (Optional)"
                class="feedback-input"
                maxlength="1000"
            />

            <span class="feedback-instruction">
                <span v-if="!text">
                    Maximum 1000 characters
                </span>
                <span v-if="text">
                    {{ 1000-text.length }} {{ 1000-text.length===1?'character':'characters' }} left
                </span>
            </span>
        </div>

        <br class="reset">
        <div
            v-if="error"
            class="feedback-error"
        >
            <svg
                style="width: 17px;margin-right: 10px;color:rgb(184, 18, 55)"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times-circle"
                class="svg-inline--fa fa-times-circle fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
                <path
                    fill="currentColor"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                />
            </svg>
            Please select Thumbs up or Thumbs down, or leave a comment.
        </div>
        <button
            class="feedback-send"
            @click="send"
        >
            Send
        </button>
    </div>
</template>
<script>

    import Base from "./Base.vue"
    import TUTD from "./TUTD.vue"
    import OneGovApi from '../clients/OneGovApi'
    export default {
        name: "SinglePageFeedback",
        components: {TUTD},
        mixins: [Base],
        data:()=>({
            picked:"",
            error:false,
            text:""
        }),
        mounted() {
            this.picked = this.$route && this.$route.query && this.$route.query.initial
        },
        methods:{
            onPicked(value){
              this.picked=value
              this.error=false
            },
            send(){
              if(!this.picked && !this.text){
                this.error=true
                return
              }
              const params = new URLSearchParams(window.location.search)
              const applicationName = params.get('applicationName') || location.hostname
              const sourceApplication = params.get('sourceApplication') || 'Notifications-HP-EmailFeedback'
              const rating = (this.picked === 'up') ? 5 : 1
              OneGovApi.addComment(this.text, applicationName, sourceApplication,rating).finally(()=>{
                window.location = './thankyou'
              })
            }
        }
    }
</script>
<style scoped>
  .welcome{
    margin-top: 32px;
    margin-bottom: 8px;
    color: rgb(36, 41, 52);
    font-size: 24px;
    font-weight: 500;
  }
  .improve{
    color: rgb(36, 41, 52);
    font-size: 16px;
    font-weight: 300;
  }
  .buttons{
    margin: 20px 0;
    color: rgb(100, 105, 116);
    font-size: 14px;
    font-weight: 300;
    display: flex;
    align-items: center;
  }
  .buttons > * {
    margin-right: 12px;
  }
  .feedback-instruction{
    float: right;
    font-size: 12px;
    color: #646974;
  }
  .reset{
    clear: both;
  }
  .text-area-container{
    max-width: 654px;
  }
  .text-area-container > textarea{
    max-width: unset;
    height: 327px;
  }
  .feedback-send{
    background: rgb(215, 21, 58);
    border-radius: 8px;
    border: 2px solid rgb(167, 25, 48);
    height: 48px;
    width: 200px;
    color: white;
    margin: 40px 0;
  }
  @media only screen and (max-width:480px){
    .feedback-send{
      width: 100%;
    }
  }
  .feedback-error{
    margin-top: 10px;
  }
  .feedback-error-text{
    border: 2px solid rgb(184, 18, 55);
  }
</style>