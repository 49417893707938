var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "thankyou-body" }, [
      _c("div", { staticClass: "welcome" }, [
        _vm._v("\n        Your feedback is welcome\n    "),
      ]),
      _c("div", { staticClass: "improve" }, [
        _vm._v(
          "\n        We've received your feedback and we'll take it from here.\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }