export default {
    addComment: (comment, applicationName, sourceApplication, rating='0') => {
        const url = process.env.VUE_APP_ONEGOV_SUBMIT_URL
        const params = new URLSearchParams()
        params.append('rating', rating)
        params.append('comments', comment)
        params.append('applicationName',applicationName)
        params.append('sourceApplication',sourceApplication)
        params.append('userAgent',navigator.userAgent)
        return fetch(url,{method:'POST',headers:{'Content-Type':'application/x-www-form-urlencoded'}, body:params.toString()})
    }
}