var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.crumbs.length > 0
    ? _c(
        "header",
        { staticClass: "page-header", attrs: { id: "breadcrumb" } },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "nav",
              {
                staticClass: "breadcrumb",
                attrs: { role: "navigation", "aria-label": "Breadcrumbs" },
              },
              [
                _c(
                  "ol",
                  [
                    _c("li", { attrs: { "data-test": "breadcrumb-item" } }, [
                      _c(
                        "a",
                        {
                          staticClass: "sub-header",
                          attrs: { href: "#" },
                          on: { click: _vm.backToDashboard },
                        },
                        [_vm._v("Home")]
                      ),
                    ]),
                    _vm._l(_vm.crumbs, function (crumb, index) {
                      return _c(
                        "li",
                        {
                          key: `crumb-${index}`,
                          attrs: { "data-test": "breadcrumb-item" },
                        },
                        [
                          crumb.action
                            ? _c(
                                "a",
                                {
                                  attrs: { href: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return crumb.action.apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(crumb.label))]
                              )
                            : crumb.route
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "breadcrumb-item",
                                  attrs: {
                                    "data-test": `breadcrumb-link-${index}`,
                                    to: crumb.route,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { id: "breadcrumb-link-val" } },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(crumb.label) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "strong",
                                { attrs: { "data-test": "breadcrumb-child" } },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(crumb.label) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
            _c("h1", { staticClass: "header" }, [
              _vm._v("\n            " + _vm._s(_vm.header) + "\n        "),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }