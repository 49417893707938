<!-- eslint-disable -->
<template>
    <div id="topmost">
        <div class="container" id ="top">
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="nav-title h1">MyServiceNSW Account</div>
                </div>
            </div>
        </div>
        <div class="container nav-container">
            <NavBar/>
        </div>
        <br/>
        <Breadcrumbs
                :crumbs="breadcrumbs"
                :header="header"
                @back-to-dashboard="backToDashboard"
        />


        <div class="container">
            <router-view
                    @dirty="setDirtyChildren"
                    @back-to-dashboard="backToDashboard"
                    @childinit="setBreadcrumbs"
            />
        </div>
        <div
                v-if="isFeedback"
                class="feedback-footer"
        >
            <div class="container">
                If you need a response, <a href="https://www.service.nsw.gov.au/contact-us" target="_blank">send an enquiry</a> instead.
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import 'scss/main';
    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-width: 320px;
    }
</style>
<style scoped>
    .feedback-footer{
        min-height: 64px;
        vertical-align:middle;
        display:table-cell;
        width: 100vw;
        padding: 15px 0;
        background: rgb(244, 247, 249);
    }

    .nav-title {
        margin: calc(40px / 1.6666) 0 calc(48px / 1.5);
        font-family: Gotham A,Gotham B,Helvetica,Arial,sans-serif;
        font-weight: 700;
        font-size: 2rem;
    }

    .nav-container {
        max-width: 100%;
        padding-right: 0;
        padding-left: 0;
        box-shadow: 0px 2px 4px 0px rgb(207 212 214);
        width: 100%;
    }

    @media (max-width: 768px) {
        .nav-title.h1 {
            font-size: 1.375rem;
            margin: .65rem 0;
            font-weight: 700;
            line-height: 1.4;
            font-family: Gotham A, Gotham B, Helvetica, Arial, sans-serif;
        }
    }

    @media (min-width: 768px) {
        .col-md-12 {
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .col-sm-12 {
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .nav-title.h1 {
            margin-top: 40px;
            margin-bottom: 40px;
            font-size: 32px;
        }
    }

    @media (min-width: 412px) {
        .col-sm-12 {
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .h1{
            font-size: 2.25rem;
            margin: .65rem 0;
        }
    }

    @media (max-width: 420px) {
        #top {
            padding-left: 10px;
        }
    }

    #topmost{
        border-top-width:.5px;
        border-top-color: #dee3e5;
        border-top-style: solid;
        border-top-radius:0px;
        border-bottom:2px solid;
        border-bottom-color:#dee3e5;

      flex-grow: 10;
    }
    #top{
        border-bottom:2px solid;
        border-bottom-color:#dee3e5;
    }


</style>

<script>
    import LogoutApi from "./clients/LogoutApi.js"
    import Breadcrumbs from "./components/Breadcrumbs.vue"
    import NavBar from "./components/NavBar.vue"
    import { EventBus } from "./clients/event-bus.js"

    export default {
        name: 'App',
        components: { NavBar, Breadcrumbs},
        data: () => ({
            dirty: false,
            breadcrumbs: [],
            header: '',
            myAccountLink: '',
            logoutLink: '',
            globalNavUrl: '',
            globalNavApiKey: '',
            isFeedback:false,
            isSavePreference:false
        }),
        async created() {
            window.onbeforeunload = this.messageOnClose
            this.myAccountLink = process.env.VUE_APP_MYACCOUNT_DASHBOARD_URL
            this.logoutLink = process.env.VUE_APP_MYACCOUNT_LOGOUT_URL
            this.globalNavUrl = process.env.VUE_APP_GLOBAL_NAV_URL
            this.globalNavApiKey = process.env.VUE_APP_GLOBAL_NAV_API_KEY


        },
      beforeCreate() {
        EventBus.$on('show-save-preference', (val) => {
          this.isSavePreference = Boolean(val)
        }, )
      },
      beforeDestroy() {
        EventBus.$off('show-save-preference')
      },
        mounted() {
            let globalNav = document.createElement('script')
            globalNav.defer = true
            globalNav.async = true
            globalNav.setAttribute('src', `${this.globalNavUrl}?apikey=${this.globalNavApiKey}`)
            document.head.appendChild(globalNav)
            if(this.$router.currentRoute.path.endsWith("feedback")){
                this.isFeedback=true
            }
        },
        methods: {
            setDirtyChildren(isDirty) {
                this.dirty = isDirty
            },
            backToDashboard() {
                if (!this.dirty) {
                    LogoutApi.logout()
                }
                window.open(process.env.VUE_APP_MYACCOUNT_DASHBOARD_URL, '_self')
            },
            messageOnClose() {
                return this.dirty ? true : undefined
            },
            setBreadcrumbs(crumbs, header) {
                this.breadcrumbs = crumbs
                this.header = header
            }
        }
    }
</script>
