<template>
    <header
        v-if="crumbs.length > 0"
        id="breadcrumb"
        class="page-header"
    >
        <div class="container">
            <nav
                class="breadcrumb"
                role="navigation"
                aria-label="Breadcrumbs"
            >
                <ol>
                    <li data-test="breadcrumb-item">
                        <!-- eslint-disable -->
                        <a class="sub-header"
                            href="#"
                            @click="backToDashboard"
                        >Home</a>
                        <!-- eslint-enable -->
                    </li>
                    <li
                        v-for="(crumb, index) in crumbs"
                        :key="`crumb-${index}`"
                        data-test="breadcrumb-item"
                    >
                        <!-- eslint-disable -->
                        <a v-if="crumb.action" href="" v-on:click.prevent="crumb.action">{{ crumb.label }}</a>
                        <!-- eslint-enable -->
                        <router-link
                            v-else-if="crumb.route"
                            :data-test="`breadcrumb-link-${index}`"
                            :to="crumb.route"
                            class="breadcrumb-item"
                        >
                            <span id="breadcrumb-link-val">
                                {{ crumb.label }}
                            </span>
                        </router-link>
                        <strong
                            v-else
                            data-test="breadcrumb-child"
                        >
                            {{ crumb.label }}
                        </strong>
                    </li>
                </ol>
            </nav>
            <h1 class="header">
                {{ header }}
            </h1>
        </div>
    </header>
</template>

<script>
    export default {
        name: "Breadcrumbs",
        props: {
            crumbs: {
                type: Array,
                required: true,
                default: () => []
            },
            header: {
                type: String,
                required: true,
                default: () => ''
            }
        },
        methods: {
            backToDashboard() {
                this.$emit('back-to-dashboard')
            }
        }
    }
</script>

<style scoped lang="scss">

    .breadcrumb {
        margin-bottom: 30px;
    }
    .page-header {
        background: white;
      padding-bottom: 10px;
    }
    .header{
      padding: 0px;
      font-size: 24px;
      font-weight: bold;
      color: rgb(36,41,52);
    }
    .breadcrumb-item {
      display: inline-block;
      text-decoration: underline;
    }
    @media (max-width: 420px) {
      .header {
        overflow-wrap: break-word;
      }
    }
</style>
