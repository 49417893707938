var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "welcome" }, [
      _vm._v("\n        Your feedback is welcome\n    "),
    ]),
    _c("div", { staticClass: "improve" }, [
      _vm._v("\n        We'll use your feedback to improve our service.\n    "),
    ]),
    _c(
      "div",
      { staticClass: "buttons" },
      [
        _c("TUTD", {
          attrs: {
            initial:
              this.$route && this.$route.query && this.$route.query.initial,
          },
          on: { picked: _vm.onPicked },
        }),
        _c("span", [_vm._v("Click to rate")]),
      ],
      1
    ),
    _c("div", { staticClass: "text-area-container" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.text,
            expression: "text",
          },
        ],
        staticClass: "feedback-input",
        attrs: {
          placeholder: "Please provide your feedback here. (Optional)",
          maxlength: "1000",
        },
        domProps: { value: _vm.text },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.text = $event.target.value
          },
        },
      }),
      _c("span", { staticClass: "feedback-instruction" }, [
        !_vm.text
          ? _c("span", [
              _vm._v("\n                Maximum 1000 characters\n            "),
            ])
          : _vm._e(),
        _vm.text
          ? _c("span", [
              _vm._v(
                "\n                " +
                  _vm._s(1000 - _vm.text.length) +
                  " " +
                  _vm._s(
                    1000 - _vm.text.length === 1 ? "character" : "characters"
                  ) +
                  " left\n            "
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("br", { staticClass: "reset" }),
    _vm.error
      ? _c("div", { staticClass: "feedback-error" }, [
          _c(
            "svg",
            {
              staticClass: "svg-inline--fa fa-times-circle fa-w-16",
              staticStyle: {
                width: "17px",
                "margin-right": "10px",
                color: "rgb(184, 18, 55)",
              },
              attrs: {
                "aria-hidden": "true",
                focusable: "false",
                "data-prefix": "fas",
                "data-icon": "times-circle",
                role: "img",
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 512 512",
              },
            },
            [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  d: "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z",
                },
              }),
            ]
          ),
          _vm._v(
            "\n        Please select Thumbs up or Thumbs down, or leave a comment.\n    "
          ),
        ])
      : _vm._e(),
    _c("button", { staticClass: "feedback-send", on: { click: _vm.send } }, [
      _vm._v("\n        Send\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }