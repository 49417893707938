<template>
    <div class="thankyou-body">
        <div class="welcome">
            Your feedback is welcome
        </div>
        <div class="improve">
            We've received your feedback and we'll take it from here.
        </div>
    </div>
</template>

<script>
import Base from "./Base.vue"

export default {
  name: "ThankYou",
  mixins: [Base]
}
</script>

<style scoped>

.welcome{
  margin-top: 32px;
  margin-bottom: 8px;
  color: rgb(36, 41, 52);
  font-size: 24px;
  font-weight: 500;
}
.improve{
  color: rgb(36, 41, 52);
  font-size: 16px;
  font-weight: 300;
}
.thankyou-body{
  min-height: 216px;
}
</style>