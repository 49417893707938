var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "topmost" } },
    [
      _vm._m(0),
      _c("div", { staticClass: "container nav-container" }, [_c("NavBar")], 1),
      _c("br"),
      _c("Breadcrumbs", {
        attrs: { crumbs: _vm.breadcrumbs, header: _vm.header },
        on: { "back-to-dashboard": _vm.backToDashboard },
      }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("router-view", {
            on: {
              dirty: _vm.setDirtyChildren,
              "back-to-dashboard": _vm.backToDashboard,
              childinit: _vm.setBreadcrumbs,
            },
          }),
        ],
        1
      ),
      _vm.isFeedback
        ? _c("div", { staticClass: "feedback-footer" }, [_vm._m(1)])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container", attrs: { id: "top" } }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 col-sm-12" }, [
          _c("div", { staticClass: "nav-title h1" }, [
            _vm._v("MyServiceNSW Account"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container" }, [
      _vm._v("\n            If you need a response, "),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.service.nsw.gov.au/contact-us",
            target: "_blank",
          },
        },
        [_vm._v("send an enquiry")]
      ),
      _vm._v(" instead.\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }